<template>
	<div class="indexPage">
		<div class="banner">
			<!-- <headerComponent /> -->

			<div class="search">
				<div class="search-input">
					<div class="selectType">
						<el-select
							v-model="selectType"
							placeholder="请选择"
						>
							<el-option
								v-for="item in searchType"
								:key="item.id"
								:label="item.type"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</div>
					<div class="shu"></div>
					<input
						placeholder="发现更多精彩内容"
						type="text"
						v-model="searchValue"
						@keyup.enter="search"
					/>

					<div
						class="btn"
						@click="search"
					>
						<img
							:src="require('assets/images/included_search.png')"
							alt=""
						/>
					</div>
				</div>
				<!-- recommendList -->
				<div class="recommend">
					<div class="label">推荐内容：</div>
					<div
						class="recommend-item"
						v-for="(item, index) in recommendList"
						:key="index"
						@click="recommendClick(item)"
					>
						{{ item.conName }}
					</div>
				</div>
			</div>

			<el-carousel
				@change="changeCarousel"
				height="420px"
				arrow="never"
				:interval="6000"
			>
				<el-carousel-item
					v-for="(item, index) in carouselList"
					:key="index"
				>
					<!-- <img width="1920" height="420" :scr="item" /> -->
					<img
						width="100%"
						:src="item"
						alt=""
						@click="bannerClick(index)"
					/>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="suspended">
			<div
				class="web"
				@click="webClick('xiaoyu')"
			>
				<img
					:src="require('assets/writtenAi/log2.png')"
					alt=""
				/>AI绘画
			</div>
			<div
				class="jishu"
				@click="webClick('tanks')"
			>
				<img
					:src="require('assets/writtenAi/log3.png')"
					alt=""
				/>挖潜智库

				<img
					class="recommend"
					:src="require('assets/images/recommend.png')"
					alt=""
				/>
			</div>
			<div
				class="wps"
				@click=" webClick('broadcast')"
			>
				<img
					:src="require('assets/writtenAi/log1.png')"
					alt=""
				/>小语直播
			</div>
		</div>

		<div class="swiper">
			<div
				class="left"
				@click="back"
			>
				<img
					:src="require('assets/images/swiper-left.png')"
					alt=""
				/>
			</div>
			<div
				class="right"
				@click="next"
			>
				<img
					:src="require('assets/images/swiper-right.png')"
					alt=""
				/>
			</div>
			<div class="swiper-main">
				<div
					class="swiper-item"
					v-for="(item, index) in swiperList"
					:key="index"
				>
					<div class="header">
						<img
							:src="item.img"
							alt=""
							class="swiperImg"
						/>
						<span class="title">{{ item.title }}</span>
					</div>
					<div class="list">
						<div
							class="list-li"
							:title="obj.title"
							v-for="(obj, idx) in item.rank"
							:key="idx"
							@click="rankClick(obj)"
						>
							<span class="rank">{{ idx + 1 }}</span>
							<span class="fileName">{{ obj.title }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- introduceList -->
		<div class="introduce">
			<div class="left">
				<div class="sidebar">
					<div
						class="sidebar-item"
						:class="{ active: currentIndex == index }"
						v-for="(item, index) in introduceList"
						:key="index"
						@mouseover="switchInfo(item, index)"
					>
						<img
							v-show="currentIndex == index"
							:src="item.sidebarImg"
							alt=""
						/>
						{{ item.title }}
					</div>
				</div>
			</div>
			<div class="right">
				<div class="info">
					<div class="title">{{ introduceContent.title }}</div>
					<div class="content">{{ introduceContent.introduce }}</div>
					<div
						class="download"
						@click="downloadClick"
					>下载体验</div>
				</div>
				<div class="img">
					<img
						:src="introduceContent.img"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="title">智能内容与专业服务平台&nbsp;&nbsp;省时省心给力</div>
			<div class="sub"></div>
			<div class="card-main">
				<div class="card-item">
					<img
						:src="require('assets/images/top1.png')"
						alt=""
					/>
					<div class="text">
						<div class="title">产品特色</div>
						<div class="paragraph">
							<span class="label">全网素材：</span>
							<span class="value">收集全网优质素材，包括百度、政府官网网站、等平台。
							</span>
						</div>
						<div class="paragraph">
							<span class="label">智能拆解：</span>
							<span class="value">AI解析文章内容，提供文章、段落、标准句、图片、AI绘画等多维度写作素材。
							</span>
						</div>
						<div class="paragraph">
							<span class="label">写作角度：</span>
							<span class="value">AI推荐写作角度，激发您的写作灵感，实现快速写作新体验。
							</span>
						</div>
					</div>
				</div>
				<div class="card-item">
					<img
						:src="require('assets/images/top2.png')"
						alt=""
					/>
					<div class="text">
						<div class="title">AI核心功能</div>
						<div class="paragraph">
							<span class="label">内容创作：</span>
							<span class="value">拥有智能推文、AI成文、智能推图、AI绘画、网页采集、文本核校、原创溯源等AI创作核心功能。每个功能都为解决应用痛点而生，帮助内容创作者全面提升内容创作效率。
							</span>
						</div>
						<div class="paragraph">
							<span class="label">平台应用：</span>
							<span class="value">适用电脑端、移动端多平台应用。 </span>
						</div>
						<div class="paragraph">
							<span class="label">数据安全：</span>
							<span class="value">云端加密存储，内容私密，安全有保障。 </span>
						</div>
					</div>
				</div>
				<div class="card-item">
					<img
						:src="require('assets/images/top3.png')"
						alt=""
					/>
					<div class="text">
						<div class="title">“百科全书”式文库</div>
						<div class="paragraph">
							<span class="label">专业文库：</span>
							<span class="value">全面汇聚时事新闻、法律法规、工作报告、政策文件、裁判文书等十余类专业文献。实时、全面、权威展示各类文书。
							</span>
						</div>
						<div class="paragraph">
							<span class="label">优质模板：</span>
							<span class="value">整合公文写作、法律文书、合同协议、活动策划、商业企划等十余类的优质模板资源。
							</span>
						</div>
						<div class="paragraph">
							<span class="label">闪亮金句：</span>
							<span class="value">收录公文写作、儒家经典、中小学作文等二十余类闪亮金句的“百宝智囊”。
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="gonf">
			<img
				:src="require('@/assets/bgc15.png')"
				alt=""
				class="banner"
			/>
		</div>
		<div class="data">
			<div class="title">技术支持&nbsp;&nbsp;数据概览</div>
			<div class="sub"></div>
			<div class="show-data">
				<div class="item">
					<div class="num">
						<span>{{ allData.allContentNumToday || 0 }}</span>篇
					</div>
					<div class="label">今日新增</div>
				</div>
				<div class="item">
					<div class="num">
						<span>{{ allData.allContentNum || 0 }}</span>篇
					</div>
					<div class="label">总量</div>
				</div>
				<div class="item">
					<div class="num">
						<span>{{ allData.recommend || 0 }}</span>次
					</div>
					<div class="label">算法调用</div>
				</div>
			</div>
			<div class="data-info">
				<div class="info-item">
					<div class="img">
						<img
							:src="require('assets/images/bg2-img1.png')"
							alt=""
						/>
					</div>
					<div class="title">专职研发团队</div>
					<div class="text">
						拥有大神级技术大牛带领的专职研发团队，已开展3年智能写作AI算法训练，技术达到行业领先水平。
					</div>
				</div>
				<div class="info-item">
					<div class="img">
						<img
							:src="require('assets/images/bg2-img2.png')"
							alt=""
						/>
					</div>
					<div class="title">资深精英团队</div>
					<div class="text">
						拥有行政文秘、文书创作、法律服务、商业服务等多个资深精英团队，提供专业、高端的内容服务支持。
					</div>
				</div>
				<div class="info-item">
					<div class="img">
						<img
							:src="require('assets/images/bg2-img3.png')"
							alt=""
						/>
					</div>
					<div class="title">24小时不间断更新</div>
					<div class="text">
						无论文库还是软件和算法，都全天24小时不间断更新，确保内容最新、技术最新、应用最新。
					</div>
				</div>
			</div>
		</div>
		<div class="dynamic">
			<div class="title">企业动态</div>
			<div class="sub"></div>

			<div class="dynamic_box">
				<div
					class="item_content"
					@click="
            $router.push({
              path: '/firmDetails',
              query: {
                id: item.id,
              },
            })
          "
					v-for="(item, index) in list"
					v-show="index < 3"
					:key="item.id"
				>
					<div class="right">
						<img
							:src="$store.state.onlineBasePath + item.imgUrl"
							alt=""
						/>
					</div>
					<div class="left">
						<div class="date">{{ item.createTime }}</div>

						<div class="title">
							{{ item.title }}
						</div>

						<div class="text">
							{{ item.content }}
						</div>

						<!-- <div class="more">更多></div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="commonFooter_box">
			<commonFooter />
		</div>
	</div>
</template>

<script>
import {
  getEsDateNum,
  LibraryIndexList,
  findDynamicInfoList,
} from "api/common";
import qs from "qs";
import headerComponent from "./headerComponent";
import commonFooter from "components/footer/commonFooter.vue";
export default {
  components: {
    commonFooter,
    // headerComponent,
  },
  data() {
    return {
      allData: {},
      carouselList: [
        require("@/assets/images/banner01.png"),
        require("@/assets/images/Vip_banner.png"),
        require("@/assets/images/banner02.png"),
        require("@/assets/images/banner03.png"),
        require("@/assets/images/banner04.png"),
        require("@/assets/images/banner05.png"),
      ],

      navList: [
        {
          title: "AI创作",
          url: "",
        },
        {
          title: "专业文库",
          url: "/infoCompileIndex",
          children: [],
        },
        {
          title: "优质模板",
          url: "/templatetHtml",
          children: [],
        },
        {
          title: "闪亮金句",
          url: "/goldenSentence/goldenSentenceIndex",
          children: [],
        },
        {
          title: "软件下载",
          url: "/downloadGuide",
        },
        {
          title: "企业动态",
          url: "/downloadGuide",
        },
        {
          title: "合作咨询",
          url: "/downloadGuide",
        },
      ],
      recommendList: [
        {
          conName: "重大会议",
          labelId: "WKzhongdhy00001",
          type: 1,
        },
        {
          conName: "新闻联播",
          labelId: "WKxinwlb00001",
          type: 1,
        },
        {
          conName: "公文写作",
          labelId: "MBgongwxz00001",
          type: 2,
        },
        {
          conName: "法律文书",
          labelId: "MBfalws00001",
          type: 2,
        },

        {
          conName: "儒家经典",
          labelId: "JJrujjd00001",
          type: 3,
        },
      ],
      isShowDownMenu: false,
      searchValue: "",
      selectType: 2,
      currentType: {
        id: 1,
        type: "全部",
      },
      searchType: [
        // {
        //   id: 1,
        //   type: "全部",
        // },
        {
          id: 2,
          type: "文库",
        },
        {
          id: 3,
          type: "模板",
        },
        {
          id: 4,
          type: "金句",
        },
      ],
      swiperList: [
        {
          img: require("assets/images/swiper1.png"),
          title: "公文写作",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },

        {
          img: require("assets/images/swiper2.png"),
          title: "法律文书",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },

        {
          img: require("assets/images/swiper3.png"),
          title: "党内法规",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },

        {
          img: require("assets/images/swiper4.png"),
          title: "法律法规",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },

        // {
        //   img: require("assets/images/swiper5.png"),
        //   title: "政策文件",
        //   rank: [
        //     {
        //       title: "二十大报告应知会知知识点",
        //     },
        //     {
        //       title: "党的二十大报告学习新的",
        //     },
        //     {
        //       title: "二十大报告应知会知知识点",
        //     },
        //     {
        //       title: "党的二十大报告学习新的",
        //     },
        //     {
        //       title: "二十大报告应知会知知识点",
        //     },
        //     {
        //       title: "党的二十大报告学习新的",
        //     },
        //   ],
        // },

        // {
        //   img: require("assets/images/swiper6.png"),
        //   title: "战略规划",
        //   rank: [
        //     {
        //       title: "二十大报告应知会知知识点",
        //     },
        //     {
        //       title: "党的二十大报告学习新的",
        //     },
        //     {
        //       title: "二十大报告应知会知知识点",
        //     },
        //     {
        //       title: "党的二十大报告学习新的",
        //     },
        //     {
        //       title: "二十大报告应知会知知识点",
        //     },
        //     {
        //       title: "党的二十大报告学习新的",
        //     },
        //   ],
        // },
      ],
      swiperPage: 1,
      swiperAllList: [
        {
          img: require("assets/images/swiper1.png"),
          title: "公文写作",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },

        {
          img: require("assets/images/swiper2.png"),
          title: "法律文书",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },

        {
          img: require("assets/images/swiper3.png"),
          title: "党内法规",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },

        {
          img: require("assets/images/swiper4.png"),
          title: "法律法规",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },
        {
          img: require("assets/images/swiper5.png"),
          title: "政策文件",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },

        {
          img: require("assets/images/swiper6.png"),
          title: "战略规划",
          rank: [
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
            {
              title: "二十大报告应知会知知识点",
            },
            {
              title: "党的二十大报告学习新的",
            },
          ],
        },
      ],
      currentIndex: 0,
      introduceContent: {
        title: "智能推文",
        sidebarImg: require("assets/images/sidebar1.png"),
        introduce:
          "根据鼠标选中或者输入的文本，精准匹配30余种中文语境算法引擎，实时智能提供优选内容方案，一键复制进编辑框，大幅降低创作难度、激发创作灵感。（隐藏功能：使用成语Office/WPS版，采纳推荐内容时可以自动插入规范的脚注尾注，大学论文写作神器）",
        img: require("assets/images/infoImg1.png"),
      },
      introduceList: [
        {
          title: "智能推文",
          sidebarImg: require("assets/images/sidebar1.png"),
          introduce:
            "根据鼠标选中或者输入的文本，精准匹配30余种中文语境算法引擎，实时智能提供优选内容方案，一键复制进编辑框，大幅降低创作难度、激发创作灵感。（隐藏功能：使用成语Office/WPS版，采纳推荐内容时可以自动插入规范的脚注尾注，大学论文写作神器）",
          img: require("assets/images/infoImg1.png"),
        },
        {
          title: "AI成文",
          introduce:
            "根据鼠标选中或者输入的文本，精准匹配30余种中文语境算法引擎，实时智能提供优选内容方案，一键复制进编辑框，大幅降低创作难度、激发创作灵感。（隐藏功能：使用成语Office/WPS版，采纳推荐内容时可以自动插入规范的脚注尾注，大学论文写作神器）",
          img: require("assets/images/infoImg2.png"),
          sidebarImg: require("assets/images/sidebar2.png"),
        },
        {
          title: "智能推图",
          introduce:
            "制作PPT材料时，“智能推图”可以根据选中的内容推荐站内站外的图片；（隐藏功能：聊天斗图，站内站外粘贴复制立即可用）",
          img: require("assets/images/infoImg3.png"),
          sidebarImg: require("assets/images/sidebar3.png"),
        },
        {
          title: "AI绘画",
          introduce:
            "当灵感匮乏时，可以输入指令，驱动AI智能创造独一无二的原创画作；（隐藏功能：独特、有趣、解压的绘画创作）",
          img: require("assets/images/infoImg4.png"),
          sidebarImg: require("assets/images/sidebar4.png"),
        },
        {
          title: "网页采集",
          introduce:
            "找资料、浏览网页时，使用“网页采集”功能，可以将当前网页转化为word文档后存入自带的网盘库，并可以作为智能推文的语境库（隐藏功能：一键在手，天下文章我有）",
          img: require("assets/images/infoImg5.png"),
          sidebarImg: require("assets/images/sidebar5.png"),
        },
        {
          title: "文库资料",
          introduce:
            "全面汇聚领导人时事新闻、重大会议、新闻联播、新闻发布、党内法规、法律法规、工作报告、战略规划、政策文件、裁判文书、执法文书等十余类专业文献，这些文档都是word版本，可以随时随地查看、下载、分享。更有公文写作、基层党建、个人文稿、法律文书、合同协议、机关制度、活动策划、节日庆典、大学生类、教师专用、文秘专用、公司管理、企业制度、商业计划书等优秀模板...",
          img: require("assets/images/infoImg6.png"),
          sidebarImg: require("assets/images/sidebar6.png"),
        },
        {
          title: "截图取字",
          introduce:
            "当想要将网页上不可复制的内容粘贴到文档中，或者碰到不可编辑的宣传册等PDF材料时，“截图取字”可以将截图区域中的文字识别转化为可编辑的文本，抛弃传统做法，无需下载转文本，毫秒级无死角、超高精确度，想取哪里截哪里。",
          img: require("assets/images/infoImg7.png"),
          sidebarImg: require("assets/images/sidebar7.png"),
        },
      ],
      list: [],
      total: 0,
      CarouselIndex: 0,
    };
  },
  created() {
    this.getSwiperList();
    this.getCount();
    this.getList(121);
  },
  methods: {
    bannerClick(index) {
      if (index == 0) {
        window.open("http://work.writemall.com/");
      }
      // this.$bus.$emit('showGrit')
      if (index == 1) {
        this.$bus.$emit("showGrit");
      }
      if (index == 2) {
        this.$router.push("/infoCompileIndex");
      }
      if (index == 3) {
        this.$router.push("/templatetHtml");
      }
      if (index == 4) {
        this.$router.push("/goldenSentence/goldenSentenceIndex");
      }
      if (index == 5) {
        if (this.$store.state.user_info.memberlevel == 1) {
          this.$router.push("/details/1/1");
        }
        if (
          this.$store.state.user_info.memberlevel == 2 ||
          this.$store.state.user_info.memberlevel == 3
        ) {
          this.$router.push("/details/1/2");
        }
      }
    },
    rankClick(obj) {
      this.$router.push({
        name: "InfoCompileIndex",
        params: {
          articleClick: obj.md5Label,
        },
      });
    },
    downloadClick() {
      const link = document.createElement("a");
      link.href =
        "https://qiniu.writemall.com/ChengyuSpeedEdition/%E6%88%90%E8%AF%AD%E6%9E%81%E9%80%9F%E7%89%88%28windows%E7%B3%BB%E7%BB%9F%29.zip";
      link.download = "download";
      link.click();
    },
    recommendClick(item) {
      if (item.type == 1 || this.CarouselIndex == 1) {
        this.$router.push({
          name: "InfoCompileIndex",
          query: {
            labelId: item.labelId,
          },
        });
      } else if (item.type == 2 || this.CarouselIndex == 2) {
        this.$router.push({
          name: "templatetHtml",
          query: {
            labelId: item.labelId,
          },
        });
      } else if (item.type == 3 || this.CarouselIndex == 3) {
        this.$router.push({
          name: "GoldenSentenceIndex",
          query: {
            labelId: item.labelId,
          },
        });
      }
    },
    changeCarousel(index) {
      this.CarouselIndex = index;
      if (index == 1) {
        this.recommendList = [
          {
            conName: "领导人时事新闻",
            labelId: "WKlingdrssx00001",
          },
          {
            conName: "重大会议",
            labelId: "WKzhongdhy00001",
          },
          {
            conName: "新闻联播",
            labelId: "WKxinwlb00001",
          },
          {
            conName: "新闻发布",
            labelId: "WKxinwfb00001",
          },
          {
            conName: "党内法规",
            labelId: "WKdangnfg00001",
          },
        ];
      } else if (index == 2) {
        this.recommendList = [
          {
            conName: "公文写作",
            labelId: "MBgongwxz00001",
          },
          {
            conName: "法律文书",
            labelId: "MBfalws00001",
          },
          {
            conName: "合同协议",
            labelId: "MBhetxy00001",
          },
          {
            conName: "机关制度",
            labelId: "MBjigzd00001",
          },
          {
            conName: "活动策划",
            labelId: "MBhuodch00001",
          },
          {
            conName: "节日庆典",
            labelId: "MBjierqd00001",
          },
        ];
      } else if (index == 3) {
        this.recommendList = [
          {
            conName: "党的二十大",
            labelId: "JJdangdesd00001",
          },
          {
            conName: "核心金句",
            labelId: "JJhexjj00001",
          },
          {
            conName: "毛主席诗词名言",

            labelId: "JJmaozxscmy00001",
          },
          {
            conName: "公文写作",
            labelId: "JJgongwxz00001",
          },
          {
            conName: "儒家经典",
            labelId: "JJrujjd00001",
          },
          {
            conName: "道家经典",
            labelId: "JJdaojjd00001",
          },
        ];
      } else {
        this.recommendList = [
          {
            conName: "重大会议",
            labelId: "WKzhongdhy00001",
            type: 1,
          },
          {
            conName: "新闻联播",
            labelId: "WKxinwlb00001",
            type: 1,
          },
          {
            conName: "公文写作",
            labelId: "MBgongwxz00001",
            type: 2,
          },
          {
            conName: "法律文书",
            labelId: "MBfalws00001",
            type: 2,
          },
          {
            conName: "公文写作",
            labelId: "JJgongwxz00001",
            type: 3,
          },
          {
            conName: "儒家经典",
            labelId: "JJrujjd00001",
            type: 3,
          },
        ];
      }
    },
    webClick(type) {
      // window.open("http://work.writemall.com/");\
      // this.$bus.$emit("professional", "xiaoyu");
      this.$router.push({
        path: "/professional",
        query: {
          type,
        },
      });
    },
    jishuClick() {
      console.log(1);
      this.$router.push("/speedPage");
    },
    async getSwiperList() {
      const res = await LibraryIndexList();
      if (res.code == 200) {
        res.data.forEach((item, index) => {
          // console.log(item.rank);
          this.swiperAllList[index].rank = item.rank;
          this.swiperAllList[index].title = item.name;
          if (index <= 3) {
            this.swiperList[index].rank = item.rank;
            this.swiperList[index].title = item.name;
          }

          // console.log(index);
        });
      }
    },

    switchInfo(item, index) {
      this.introduceContent = item;
      this.currentIndex = index;
    },
    async getCount() {
      const res = await getEsDateNum();
      this.allData = res.data || {};
    },
    handleMouseOut() {
      this.isShowDownMenu = false;
    },
    handleMouseOver(item) {
      if (item.children) {
        this.isShowDownMenu = true;
      } else {
        this.isShowDownMenu = false;
      }
    },
    back() {
      if (this.swiperPage == 1) {
        return;
      }
      if (this.swiperPage == 2) {
        this.swiperPage--;
        this.swiperList = this.swiperAllList.slice(0, 4);
        console.log(111, this.swiperList);
        return;
      } else {
        this.swiperList = this.swiperAllList.slice(
          this.swiperPage - 1 * 4,
          this.swiperPage - 1 * 4 + 4
        );
      }
    },
    next() {
      const tempArr = this.swiperAllList.slice(
        this.swiperPage * 4,
        this.swiperPage * 4 + 4
      );
      this.swiperPage++;
      if (tempArr.length == 0) {
        return;
      } else {
        this.swiperList = tempArr;
      }
    },

    search() {
      if (this.selectType == 2 || this.selectType == 1) {
        this.$router.push({
          name: "InfoCompileIndex",
          params: {
            searchValue: this.searchValue,
          },
        });
      }
      if (this.selectType == 3) {
        this.$router.push({
          name: "templatetHtml",
          params: {
            searchValue: this.searchValue,
          },
        });
      }
      if (this.selectType == 4) {
        this.$router.push({
          name: "GoldenSentenceIndex",
          params: {
            searchValue: this.searchValue,
          },
        });
      }
    },

    async getList(id) {
      const data = {
        parentId: id,
      };

      const res = await findDynamicInfoList(qs.stringify(data));

      if (res.code == 200) {
        this.list = res.data.data;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indexPage {
  position: absolute;
  background: #f5f5f2;
  top: 0px;
  left: 0;
  width: 100%;
  // height: 100vh;
  .banner {
    width: 100%;
    height: 420px;
    position: relative;
    ::v-deep .el-carousel {
      .el-carousel__indicators {
        bottom: 50px;
      }
    }

    .header {
      position: absolute;
      top: 0;
      z-index: 11;
      width: 100%;
      display: flex;
      padding: 15px 195px;
      box-sizing: border-box;
      .downMenu {
        height: 0px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.9);
        overflow: hidden;
        transition: all 0.3s;
        position: absolute;
        left: 0;
        z-index: 11;
        top: 67px;
      }
      .isShow {
        height: 313px;
      }
      .nav {
        flex: 1;
        display: flex;
        align-items: center;
        .nav-item {
          font-size: 16px;
          color: #fffefe;
          margin-right: 59px;
          cursor: pointer;
          &:hover {
            color: #ff6500;
          }
        }
      }
      .userInfo {
        display: flex;
        align-items: center;
        .vipServer {
          display: flex;
          align-items: center;
          font-size: 13px;
          margin-right: 27px;
          color: #fffefe;
          img {
            margin-right: 8px;
            width: 16px;
            height: 16px;
          }
        }
        .user {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #fffefe;
          img {
            margin-right: 9px;
            width: 37px;
            height: 37px;
            border-radius: 50%;
          }
        }
      }
      > img {
        width: 108px;
        height: 37px;
        margin-right: 74px;
      }
    }
    .open {
      background-color: rgba(255, 255, 255, 0.9);
      > .nav {
        .nav-item {
          color: #333;
          cursor: pointer;
        }
      }
      .userInfo {
        .vipServer {
          color: #333;
        }
        .user {
          color: #333;
        }
      }
    }
    .search {
      position: absolute;
      left: 659.5px;
      top: 189.5px;
      // width: 40%;
      z-index: 9;
      /* prettier-ignore */
      .search-input {
        height: 40Px;
        width: 601.1px;
        border-radius: 5px;
        background: #ffffff;

        display: flex;
        align-items: center;
        justify-content: space-between;
        .selectType {
          width: 100px;
          font-size: 14px;
          text-align: center;
          color: #333333;
          height: 100%;
          ::v-deep .el-select {
            height: 100%;
            .el-input {
              height: 100%;
             
              .el-input__inner {
                height: 100%;
                // width: 100px;
                font-size: 13PX;
                border: 0px;
              }
            }
          }
        }
        input {
          flex: 1;
          padding-left: 15px;
          height: 40px; /*no*/
        }
        .shu {
          width: 1px;
          background: #e4e4e4;
          height: 26px;
        }
        .btn {
          width: 62px;

          height: 100%;
          background: #ff6500;
          border-radius: 0px 5px 5px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 21px;
            height: 21px;
          }
        }
      }
      .recommend {
        display: flex;
        align-items: center;
        margin-top: 9px;
        flex-wrap: wrap;
        width: 601.1px;
        .label {
          margin-right: 18px;
          // width: 60px;
          height: 11px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        .recommend-item {
          // width: 64px;
          padding: 0 4px;
          height: 24px;
          // background: #ff6500;

          // opacity: 0.2;
          // background: #000000;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
          margin-right: 17px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background: #ff6500;
          }
        }
      }
    }
  }
  .suspended {
    width: 1526px;
    height: 80px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 195px;
    top: 380px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 9;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      cursor: pointer;
      img {
        margin-right: 10px;
        width: 48px;
        height: 48px;
      }

      .recommend {
        margin-left: 10px;
        width: 34px;
        height: 15px;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
  .swiper {
    width: 1528px;
    height: 318px;
    margin: 80px auto 40px;
    position: relative;
    // overflow: hidden;

    // &::before {
    //   position: absolute;
    //   top: -150px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   content: "";
    //   height: 380px;
    //   width: 145%;
    //   border-radius: 40% 40% 0 0;
    //   z-index: 2;
    //   background: #f5f5f2;
    // }

    .swiper-main {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 4;
      display: flex;
      // justify-content: space-between;
      & > div:nth-child(n + 2) {
        margin-left: 41px;
      }
      .swiper-item {
        width: 352px;
        height: 318px;
        background: #fff;
        position: relative;
        .header {
          .title {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            position: absolute;
            top: 20px;
            left: 20px;
            // transform: translateX(-50%);
            z-index: 9;
          }
          img {
            width: 100%;
            // height: 3.2rem;
          }
        }
        .list {
          padding: 30px 29px 40px;
          .list-li {
            font-size: 14px;
            font-family: Microsoft YaHei;
            display: flex;
            align-items: center;
            font-weight: 400;
            color: #000000;
            cursor: pointer;
            :hover {
              color: #ff6500;
            }
            .rank {
              margin-right: 18px;
              color: #999999;
            }

            .fileName {
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }
          & > div:nth-child(n + 2) {
            margin-top: 24px;
          }

          & > div:nth-child(1) .rank {
            color: #f26d5f;
          }
          & > div:nth-child(2) .rank {
            color: #ff8200;
          }
          & > div:nth-child(3) .rank {
            color: #4587ff;
          }
        }
      }
    }
    .left {
      position: absolute;
      top: 129px;
      left: -82px;
      cursor: pointer;
      img {
        width: 48px;
        height: 67px;
      }
    }
    .right {
      position: absolute;
      top: 129px;
      right: -82px;
      cursor: pointer;
      img {
        width: 48px;
        height: 67px;
      }
    }
  }
  .introduce {
    width: 1529px;
    height: 369px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 auto;
    display: flex;
    .left {
      box-sizing: border-box;
      padding: 25px 0;
      width: 199px;
      .sidebar {
        width: 100%;
        // text-align: center;
        .sidebar-item {
          text-align: center;
          height: 38px;
          width: 70%;
          margin: 9px auto;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #999999;
          cursor: default;
          img {
            margin-right: 10px;
            width: 22px;
            height: 22px;
          }
        }
        .active {
          border-radius: 8px;
          border-left: 10px solid #ff6500;
          box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
          color: #ff6500;
        }
        & > div:nth-child(1) {
          margin-top: 0px;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      .info {
        padding: 50px 60px 60px;
        flex: 1;
        .title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .content {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #333333;
          line-height: 32px;
          margin: 21px 0 40px;
        }
        .download {
          width: 140px;
          height: 50px;
          background: #ff6500;
          border-radius: 8px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      .img {
        width: 557px;
        height: 328px;
        margin-right: 20px;
        img {
          margin-top: 19px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .card {
    width: 100%;
    height: 836px;
    margin-top: 50px;
    background: url("~@/assets/images/indexPage-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: inline-block;
    > .title {
      width: 316px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      margin: 98px auto 20px;
    }
    .sub {
      width: 30px;
      height: 1px;
      background: #ff6500;
      margin: 0 auto;
    }
    .card-main {
      display: flex;
      width: 1473px;
      margin: 104px auto 0px;
      box-shadow: 0px 0px 35px 0px rgba(126, 50, 0, 0.16);
      .card-item {
        width: 491px;
        height: 484px;
        background: #fff;
        position: relative;
        &:nth-child(2) {
          background-color: #ff872a;
          .title,
          .label,
          .value {
            background: #ff872a;
            color: #fff !important;
          }
        }
        // &:hover,
        // &:hover .title,
        // &:hover .label,
        // &:hover .value {
        //   background: #ff872a;
        //   color: #fff !important;
        // }
        .text {
          width: 366px;
          height: 252px;
          // font-family: Microsoft YaHei;
          margin: 155px auto 0;
          .title {
            color: #ff872a;
            font-weight: bold;
            font-size: 24px;
          }
          .paragraph {
            margin: 15px 0;
            .label {
              font-family: MicrosoftYaHeiLight;
              color: #434343;
              font-weight: bold;
              font-size: 16px;
            }
            .value {
              font-family: MicrosoftYaHeiLight;
              color: #434343;
              line-height: 30px;
              font-size: 16px;
            }
          }
        }
        img {
          width: 210px;
          height: 210px;
          position: absolute;
          top: -73px;
          left: 134px;
        }
      }
    }
  }
  .gonf {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .data {
    width: 100%;
    height: 805px;
    background: url("~@/assets/images/indexPage-bg2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: inline-block;
    > .title {
      width: 218px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fff;
      line-height: 32px;
      margin: 108px auto 20px;
    }
    .sub {
      width: 30px;
      height: 1px;
      background: #fff;
      margin: 0 auto;
    }
    .show-data {
      width: 1530px;
      height: 165px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 0 auto;
      margin-top: 58px;
      padding: 0 89px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item {
        color: #333333;

        .num {
          span {
            color: #4587ff;
            font-size: 40px;
          }
        }
        .label {
          margin-top: 18px;
          padding-left: 5px;
        }
      }
    }
    .data-info {
      width: 1530px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info-item {
        width: 396px;
        height: 285px;
        background: #ffffff;
        box-shadow: 0px 4px 22px 2px rgba(2, 21, 85, 0.08);
        border-radius: 5px;
        .img {
          margin: 43px 0 23px 0;
          text-align: center;
          img {
            width: 54px;
            height: 54px;
          }
        }
        .title {
          // width: 154px;
          height: 25px;
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          text-align: center;
          line-height: 40px;
        }
        .text {
          margin: 20px auto;
          width: 314px;
          height: 75px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
    }
  }
  .dynamic {
    width: 100%;
    height: 757px;
    display: inline-block;
    > .title {
      // width: 316px;
      text-align: center;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      margin: 98px auto 20px;
    }
    .sub {
      width: 30px;
      height: 1px;
      background: #ff6500;
      margin: 0 auto;
    }

    .dynamic_box {
      display: flex;
      width: 1526px;
      justify-content: space-evenly;
      margin: 20px auto 20px;
      // background-color: #fff;

      .item_content {
        box-sizing: border-box;
        cursor: pointer;
        width: 391px;

        // &:nth-child(1) {
        //   background-color: #fff;
        // }

        &:hover {
          background-color: #fff;
        }

        .date {
          color: #999;
        }

        .left {
          padding: 20px;
        }

        .title {
          margin: 10px 0;
          font-size: 20px;
          font-weight: bold;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
        .text {
          color: #999;
          line-height: 1.8;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        .right {
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 266px;
          }
        }
      }
    }
  }
}
</style>
